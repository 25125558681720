import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import OfficeLocation, { IOfficeLocation } from '../components/office-location';
import { CTAButton } from '../components/shared/layout/cta';
import FxLayout from '../components/shared/layout/layout';
import uiService from '../components/shared/ui-service';
import { mergePreviewData } from '../services/merge-preview-data';

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        tagline
        siteUrl
      }
    }
  }
`;

export default class ContactUsPage extends React.Component<any, any> {
  uiService = uiService;
  constructor(props: any) {
    super(props);

    this.state = {
      previewPageContext: undefined,
    };
  }

  componentDidMount() {
    const IS_BROWSER = typeof window !== 'undefined';
    const staticData = this.props.pageContext;
    if (IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__) {
      this.setState({ previewPageContext: mergePreviewData(staticData, window.__PRISMIC_PREVIEW_DATA__) });
    }
  }

  render() {
    const pageContext = this.state.previewPageContext || this.props.pageContext;
    const seoMeta = pageContext.seoMeta && pageContext.seoMeta.primary;
    const contentPanels = pageContext.contentPanels || [];
    const isSDTR = process.env.TENANT === 'sdtr';
    const officeLocations =
      pageContext?.page?.data?.body.find((element: any) => element.slice_type === 'location')?.items || [];

    let pageBG;

    if (pageContext.page && pageContext.page.data.background_image) {
      pageBG = {
        backgroundImage: `url(${pageContext.page.data.background_image.url || ''})`,
      };
    }

    return (
      <FxLayout sitemap={pageContext.sitemap}>
        {
          <Helmet>
            <title>
              {(seoMeta && seoMeta.seo_title.text) +
                ' | ' +
                (this.props.data.site.siteMetadata && this.props.data.site.siteMetadata.name)}
            </title>
            <meta name="keywords" content={seoMeta && seoMeta.seo_keywords.text} />
            <meta name="description" content={seoMeta && seoMeta.seo_description.text} />
            <link
              type="canonical"
              href={`${this.props.data.site.siteMetadata.siteUrl}${pageContext.page && pageContext.page.url}`}
            />
          </Helmet>
        }
        <div
          className="fx-l-contact-us"
          style={{
            backgroundImage: pageBG && pageBG.backgroundImage ? pageBG.backgroundImage : '',
          }}
        >
          <div className="fx-l-hero fx-l-contact-us-hero" key={`${new Date().getMilliseconds()}-${Math.random()}`}>
            <div className="fx-l-hero-content fx-l-contact-us-hero-content">
              <div className="fx-container">
                <h1 className="fx-l-hero-title fx-l-contact-us-hero-title">
                  {pageContext.page && pageContext.page.data.title.text}
                </h1>
                <p className="fx-l-hero-body fx-l-contact-us-hero-body">
                  {pageContext.page && pageContext.page.data.subtitle.text}
                </p>
              </div>
            </div>
          </div>

          <section className="fx-l-contact-us-section">
            <div className="fx-container">
              <div className="fx-l-contact-us-layout">
                <div className="fx-layout-col-mobile--24">
                  {officeLocations.map((node: IOfficeLocation, index: number) => (
                    <OfficeLocation key={index} officeLocation={node} />
                  ))}
                </div>

                {contentPanels.map((panel: any) => {
                  if (panel.type !== 'collection') {
                    return panel.items.map((item: any) => {
                      if (item) {
                        const templates = item.content.document && item.content.document.data.templates;
                        const data = item.content.document && item.content.document.data;
                        if (
                          templates.find((template: any) => {
                            return template.template && template.template.uid.includes('fx-l-section--centered');
                          })
                        ) {
                          return (
                            <section
                              className={`fx-l-section ${templates
                                .map((template: any) => `${template.template && template.template.uid}`)
                                .join()
                                .replace(',', ' ')}`}
                              key={`${new Date().getMilliseconds()}-${Math.random()}`}
                            >
                              <div className="fx-l-section-content">
                                <div className="fx-container">
                                  <h3 className="fx-l-section-title">{data.title.text}</h3>
                                  <p className="fx-l-section-body">{data.body.text}</p>
                                  <CTAButton data={data} />
                                </div>
                              </div>
                            </section>
                          );
                        }
                      } else {
                        return null;
                      }
                      return null;
                    });
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </section>
        </div>
      </FxLayout>
    );
  }
}
