import * as React from 'react';
// tslint:disable-next-line: no-var-requires
const markerImage = require('../../../assets/img/map-marker-2.svg');

export default class FxPlainMapMarker extends React.PureComponent<any, any> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <div className="fx-map-point">
        <img className="fx-map-point-marker" src={markerImage} alt="Findex map marker" />
      </div>
    );
  }
}
