import React, { Component } from 'react';
import { FxAccordion } from './shared/layout/fx-accordion';
import GoogleMapReact from 'google-map-react';
import FxPlainMapMarker from './shared/layout/fx-plain-map-marker';
import { fbq } from '@hutsoninc/gatsby-plugin-facebook-pixel';

interface IInnerHTML {
  html: string;
  text?: string;
}
export interface IOfficeLocation {
  location: {
    document: {
      data: {
        address: IInnerHTML;
        contact_details: IInnerHTML;
        cta_link: IInnerHTML;
        cta_title: IInnerHTML;
        cta_analytics_event: IInnerHTML;
        coordinates: { longitude: number; latitude: number };
        name: IInnerHTML;
        opening_hours: IInnerHTML;
      };
    };
  };
}

interface OfficeLocationProps {
  officeLocation: IOfficeLocation;
}

export default class OfficeLocation extends Component<any> {
  constructor(props: OfficeLocationProps) {
    super(props);
  }

  ctaEvent = (data: IOfficeLocation['location']['document']['data']) => {
    if (data?.cta_link?.text) {
      if (data?.cta_analytics_event?.text) {
        fbq('track', data?.cta_analytics_event?.text);
      }
      window.location.replace(data?.cta_link.text);
    }
  };

  render() {
    const data = (this.props as OfficeLocationProps).officeLocation.location.document.data;
    let center = {};
    center = { lat: data.coordinates.latitude, lng: data.coordinates.longitude };

    const officeName = <h6 className="fx-l-office-location-subheader">{data?.name?.text}</h6>;

    return (
      <FxAccordion
        header={officeName}
        iconClassNames={'las la-angle-down tablet-accordion-toggle'}
        hasGutters={true}
      >
        <div className="tablet-accordion-content fx-l-office-location-container">

          <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--8 fx-l-office-location-contact">
            <div
              className="fx-l-office-location-contact-details"
              dangerouslySetInnerHTML={{ __html: data?.contact_details.html }}
            />
            <div
              className="fx-l-office-location-address"
              dangerouslySetInnerHTML={{ __html: data?.address.html }}
            />
            <div
              className="fx-l-office-location-opening-hours"
              dangerouslySetInnerHTML={{ __html: data?.opening_hours.html }}
            />
          </div>

          <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--16 fx-l-office-location-map">
            {data.coordinates.latitude !== 0 && (
              <div className="fx-l-office-location-googlemap">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyCp4zI3N102T5-qeruyL8FUfvHB62Pw16o' }}
                  defaultCenter={center}
                  center={center}
                  defaultZoom={17}
                  zoom={17}
                  yesIWantToUseGoogleMapApiInternals={true}
                >
                  <FxPlainMapMarker
                    lat={data.coordinates.latitude}
                    lng={data.coordinates.longitude}
                    clickCenter={{
                      lat: data.coordinates.latitude,
                      lng: data.coordinates.longitude,
                    }}
                  />
                </GoogleMapReact>
              </div>
            )}
          </div>

          <div className="fx-layout-col-mobile--24 fx-l-office-location-booking">
            <iframe src={data?.cta_link.text} width='100%' height='800px' scrolling='yes' frameBorder='0'></iframe>
          </div>

        </div>
      </FxAccordion>
    );
  }
}
